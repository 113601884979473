.filter {
    width: 100%;
    padding: 7px;
    border-radius: 7px;
    position: relative;

    .seperator {
        width: 100%;
        background-color: #e3e4ea;
        height: 1px;
        margin: 35px 0;
    }

    .primary-menu {
        .col {
            margin: 15px 0;
        }
    }

    .secondary-menu {
        display: none;

        div {
            margin: 15px 0;
        }
    }

    .openFilter {
        display: flex;
    }

    .filter-icon-wrapper {
        padding: 20px 0;

        .filter-icon {
            font-size: 25px;
            cursor: pointer;
        }
    }

    .colorfulIcon {
        color: #805292;
    }

    .checkbox-wrapper {
        flex: 1;
    }
}

.calendarDrWr {
    display: flex;
    position: relative;
    z-index: 99;

    .calendarDrBtn {
        cursor: pointer;
    }

    .calendarDrMenu {
        position: absolute;
        width: 70px;
        left: 17px;
        top: 42px;
        display: none;
        background-color: #fff;
        border: 1px solid #ced4da;

        p {
            padding: 0;
            margin: 0;
            width: 100%;
            text-align: center;
            font-size: 14px;

            &:hover {
                background-color: #f2f2f2;
            }

            cursor: pointer;
        }

        .selected {
            background-color: #f2f2f2;
        }
    }

    .active {
        display: block;
    }
}