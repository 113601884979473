.GalleryBox{
  padding: 20px;
  margin: 20px 0;
  &:nth-child(1),&:nth-child(3){
    border-right: 1px solid #e2e4ea;
  }
  Label{
    font-size: 16px;
  }
  img{
    border-radius: 10px;
    transition: 0.3s all ease;
    cursor: pointer;
    &:hover{
      transform: scale(1.05);
    }
  }
}