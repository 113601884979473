table {
  thead {
    th {
      text-align: center;

      div {
        text-align: center;
      }

      .pseudo {
        width: 30px;
        height: 30px;
      }

      .headerWithImage {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        p {
          display: 'flex';
          align-items: 'center';
          text-align: center;
          margin: 0;
          margin-right: 8px;
        }
      }

      a {
        &:hover {
          .tableIconWrapper {
            img {
              filter: grayscale(0);
              transform: scale(1.15);
              &:nth-child(1) {
                opacity: 0;
              }

              &:nth-child(2) {
                opacity: 1;
              }
            }
          }
        }

        .tableIconWrapper {
          position: relative;
          width: 30px;
          height: 30px;

          img {
            width: 30px;
            height: 30px;
            object-fit: 'contain';
            transition: 0.35s all ease-in-out;
            position: absolute;
            top: 0;
            right: 0;

            &:nth-child(1) {
              opacity: 1;
            }

            &:nth-child(2) {
              opacity: 0;
            }

            filter: grayscale(1);


          }
        }

      }

    }
  }

  tbody {
    tr {
      text-align: center;

      td {
        text-align: center;
        align-items: center;
        align-content: center;

        p {
          white-space: normal;
          // max-width: 200px;
          text-align: center
        }
      }
    }
  }
}