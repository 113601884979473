body[data-layout-mode="light"] {
    .statistic_skeleton {
      background-color: #e1e1e1 !important;
  
      &::before {
        background: linear-gradient(
          to right,
          transparent 0%,
          #e8e8e8 50%,
          transparent 100%
        ) !important;
      }
    }
  }
  
  .statistic_skeleton {
    background: #ffffff;
    border-radius: 4px;
    height: 300px;
    position: relative;
    overflow: hidden;
  }
  
  .statistic_skeleton::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      transparent 0%,
      #f8f8fb 50%,
      transparent 100%
    );
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  
  @keyframes load {
    from {
      left: -150px;
    }
    to {
      left: 100%;
    }
  }