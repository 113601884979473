
//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";


@import "theme-light";
// @import "theme-dark";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
// @import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
// @import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
// @import "custom/components/demos";
@import "custom/components/print";
@import "custom/components/badge";
@import "custom/components/table-loader";

// Plugins
@import "custom/plugins/custom-scrollbar";
// @import "custom/plugins/calendar";
// @import "custom/plugins/calendar-full";
// @import "custom/plugins/dragula";
// @import "custom/plugins/session-timeout";
// @import "custom/plugins/range-slider";
// @import "custom/plugins/sweatalert2";
// @import "custom/plugins/rating";
// @import "custom/plugins/parsley";
// @import "custom/plugins/select2";
@import "custom/plugins/icons/fontawesome-all";
// @import "custom/plugins/switch";
// @import "custom/plugins/colorpicker";
// @import "custom/plugins/timepicker";
// @import "custom/plugins/datepicker";
// @import "custom/plugins/bootstrap-touchspin";
// @import "custom/plugins/form-editors";
// @import "custom/plugins/form-upload";
// @import "custom/plugins/form-wizard";
// @import "custom/plugins/datatable";
// @import "custom/plugins/responsive-table";
// @import "custom/plugins/table-editable";
// @import "custom/plugins/apexcharts";
// @import "custom/plugins/echarts";
// @import "custom/plugins/flot";
// @import "custom/plugins/sparkline-chart";
// @import "custom/plugins/google-map";
// @import "custom/plugins/vector-maps";
// @import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
// @import "custom/pages/ecommerce";
// @import "custom/pages/email";
// @import "custom/pages/file-manager";
// @import "custom/pages/chat";
// @import "custom/pages/projects";
// @import "custom/pages/contacts";
// @import "custom/pages/crypto";
// @import "custom/pages/coming-soon";
// @import "custom/pages/timeline";
@import "custom/pages/extras-pages";
// @import "custom/pages/jobs";


::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0,0,0,0.1);
  }
  ::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: rgba(0,0,0,0.2);
  }
  ::-webkit-scrollbar-thumb:hover{
  	background: rgba(0,0,0,0.4);
  }
  ::-webkit-scrollbar-thumb:active{
  	background: rgba(0,0,0,.9);
  }